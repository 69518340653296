@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Proxima Nova';
  src: url('./ProximaNova-Semibold.woff2') format('woff2'),
    url('./ProximaNova-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./ProximaNova-Regular.woff2') format('woff2'),
    url('./ProximaNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
