:root {
  --color-primary: #df3c26;
  --color-primary-light: #e2624d;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-off-white: #f0f0f0;
  --color-success: #389e0d;
  --color-error: #ff0000;
  --color-unprocessed: #e19617;
  --color-in-progress: #0505f0;
  --bg-light-black: #0000000f;
}

/* Breakpoints Mixins */
@screen1450: ~'only screen and (max-width: 1450px)';
@screen1150: ~'only screen and (max-width: 1150px)';
@screen1024: ~'only screen and (max-width: 1024px)';
@screen991: ~'only screen and (max-width: 991px)';
@screen767: ~'only screen and (max-width: 767px)';
@screen540: ~'only screen and (max-width: 540px)';
@screen375: ~'only screen and (max-width: 375px)';
@screen320: ~'only screen and (max-width: 320px)';
