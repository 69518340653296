@import '../variable.less';

.filter-container {
  .ant-checkbox-group {
    label {
      padding: 5px 12px;
      margin-bottom: 4px;
      &:hover {
        background-color: var(--bg-light-black);
      }
    }
  }
  .filter-btn {
    padding: 8px 7px;
  }
}
